.members-main-content {
    padding: 20px;
    max-width: 90vw; /* Adjusted to take more horizontal space */
    margin: 0 auto;
    background-color: #fef8c9; /* Adjust this color if needed */
}

.table-container {
    overflow-x: auto;
    background-color: white; /* Set the table background to white */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a shadow for a slight 3D effect */
    padding: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: #f4f4f4;
    cursor: pointer;
}

th:hover {
    background-color: #eaeaea;
}

td img {
    border-radius: 50%;
    width: 32px; /* Adjusted for a larger profile icon */
    height: 32px; /* Adjusted for a larger profile icon */
    object-fit: cover;
}