/* Dashboard Styles */

.dashboard-page {
    padding: 20px;
    margin: 0 auto;
    max-width: 1800px; /* Adjusted to fit larger chart */
    text-align: center;
    background-color: #f5f5f5; /* Light gray background for the whole page */
}

.dashboard-page h1 {
    color: #6a1b9a;
    margin-bottom: 20px;
    background-color: #fff; /* White background for the title */
    padding: 20px 0;
    border-radius: 8px; /* Optional: add some rounding for aesthetics */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add some shadow */
}

.dashboard-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px auto;
    max-width: 1400px; /* Adjusted to fit larger chart */
    width: 90%; /* Make it take up 90% of the available width */
    text-align: left;
}

.dashboard-card h2 {
    font-size: 1.2em; /* Smaller font size for "Monthly Event Counts" */
    color: #6a1b9a;
    margin-bottom: 20px;
}

.chart-container {
    position: relative;
    width: 100%; /* Full width */
    height: 600px; /* Adjust height as needed */
}

.chart-container canvas {
    width: 100% !important; /* Ensure canvas takes full width */
    height: 100% !important; /* Ensure canvas takes full height */
}